<template>
  <v-overlay :value="true" v-if="value && mode == 'overlay'" aut-overlay>
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <v-skeleton-loader
    type="table"
    v-else-if="value && mode == 'skeleton'"
    aut-skeleton
  />
</template>
<script>
export default {
  name: "LoaderContent",
  props: {
    value: {},
    mode: {
      type: String,
      default: "overlay",
    },
  },
};
</script>
